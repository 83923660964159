/* css */
/* styles.css */

.dark-background {
background-color: #d9d9d9;
}

.tables-container {
  display: flex;
  flex-wrap: wrap; /* Allows the tables to wrap to the next line */
  flex: 1; 
}


.tables-container .ant-table-thead > tr > th {
  background-color: #001529 !important;
  color: #ffffff !important;
}
.table-wrapper {
  flex: 1; 
  min-width: 300px; /* Minimum width before wrapping, adjust as needed */

}



.table-wrapper table {
  width: 100%; /* Ensures the table takes full width of its container */
}

.status-grid {
  display: grid;
  grid-template-columns: auto;
}
.status-div {
  font-weight: bold;
  font-size: 15px;
  justify-content: space-between;
  display: flex;
}

/* style={{display: "flex", justifyContent:"space-between"}} */

.normal-button {
  width: 90px;
  color: white !important;
  background-color: #33608d !important;
  font-weight: bold ; 
  font-size: 13px  !important;
}